
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { StoryblokComponent, useStoryblokState } from '@storyblok/react'
import Layout from '@/app/common/components/Layout'
import StoryblokSeo from '@/app/common/components/StoryblokSeo/StoryblokSeo'
import {
  StoryblokBlogLandingPageProps,
  createGetServerSidePropsForBlogPage,
} from '@/domains/blogs/createGetServerPropsForBlogPage'

export function BlogLandingPage(props: StoryblokBlogLandingPageProps) {
  const story = useStoryblokState(props.story)
  if (!story) {
    throw new Error('Empty story!')
  }

  return (
    <Layout>
      <StoryblokSeo story={story} metatags={story.content.metatags} />
      <StoryblokComponent
        blok={story.content}
        latestArticle={props.articles?.[0]}
      />
    </Layout>
  )
}

 const _getServerSideProps =
  createGetServerSidePropsForBlogPage('homePage')

export default BlogLandingPage


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/blog/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  